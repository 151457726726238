<template>
    <div>
        <div class="search">
            <div class="search-item" v-for="(item, index) in paramsHeaders" :key="index">
                <span>{{ item.label }}</span>
                <el-date-picker v-if="item.type === 'datePicker'" v-model="params[item.value]" type="year" size="small"
                    value-format="yyyy"  ></el-date-picker>
                <el-input v-else size="small" v-model="params[item.value]" placeholder="请输入组织名称" @input="forceUpdate"></el-input>
            </div>
            <button class="btn submit-btn ml10" @click="dataInit">搜索</button>
            <button class="btn plain-btn ml10" @click="restore">重置</button>

            <button class="btn submit-btn export" @click="exportClick">导出</button>
        </div>
        <div class="title">{{ title }}</div>

        <CommonTable :headers="headers" :tableData="tableData" :hasStatistic="hasStatistic" :max-height="720"></CommonTable>
        <!-- <div>合计</div> -->
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            default () {
                return [];
            }
        },
        paramsHeaders: Array,
        params: Object,
        getTableData: Function,
		hasTotal: Boolean
    },
    data () {
        return {
            tableData: [],
        }
    },

    computed: {
        title () {
            return this.$route.meta.label;
        },
        hasStatistic () {
            return this.hasTotal || !!this.headers.find(header => header.value === 'totalCount')
        }
    },
    watch: {
        $route: {
            handler (val) {
				// const target = this.headers.find(item => item.value === 'departCount')
				// if(target) {
				// 	target.label = this.$replaceText('辞职')
				// }
                this.restore();
            },
            immediate: true
        },
    },
    methods: {
        dataInit () {
            this.getTableData(this.params).then(res => {
                this.tableData = res;
                // this.tableData = [...res, ...res, ...res, ...res]
            })
        },
        restore () {
            Object.keys(this.params).forEach(key => {
                this.params[key] = '';
            })
            if(this.$route.path.includes('/increase')){
                this.params.year = new Date().getFullYear() + ''; 
            }
            this.dataInit();
        },
        exportClick () {
            const params = {
                ...this.params,
                export: 'excel',
            }
            this.getTableData(params).then(res => {
                // this.tableData = res;
            })
        },
		forceUpdate() {
			this.$forceUpdate()
		}
    },
}
</script>

<style lang="less" scoped>
.search {
    display: flex;
    align-items: center;

    .search-item {
        display: flex;
        align-items: center;
    }

    /deep/ .el-input {
        width: 180px;
        margin-left: 8px;
    }
}

.title {
    color: #101010;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.export {
    margin-left: auto;
}</style>